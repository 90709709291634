export default theme => ({
    root: {
        '& .MuiGrid-item': {
		border: '0px solid',
		borderBottomWidth: 1,
		borderTopWidth: 1,
		borderColor: theme.palette.text.hint,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 0,
		[theme.breakpoints.up('xs')]: {
			borderLeftWidth: 1,
			marginLeft: -1,
		}
        }
    }
})